<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="goTo()">新的创作</a-button>
    </div>
    <div class="page-attention">
      <p>
        微信公众图文素材升级为草稿箱
      </p>
      <p>1、升级后，编辑器中保存的内容将存在草稿箱中。草稿发表后即从草稿箱移除，需进入"发表记录"查看。</p>
      <p>2、如需再次使用已发表内容，请前往首页"选择已有图文"-“已发表”。</p>
      <p>3、原图文素材移入历史图文素材，请前往“草稿箱”右上角"历史图文素材"查看。历史图文素材可转存为草稿继续编辑和发表。</p>
    </div>
    <a-table class="doc-table" rowKey="id" :dataSource="dataSource" :columns="columns" :pagination="Refpagination" @change="onLoadData">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
          <a-button type="primary" class="margin-right15" size="small" @click="edit(record.app_id)">编辑
          </a-button>
          <a-button type="primary" danger size="small" @click="delete(record.app_id)">删除</a-button>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
  import {
    defineComponent,
    ref,
    reactive,
    onMounted
  } from "vue";
  import * as Api from "@/addons/client/wechatApi";
  import {
    useRouter
  } from "vue-router";

  export default defineComponent({
    setup() {
      const router = useRouter();

      const formState = reactive({
      });
      const dataSource = ref();
      const Refpagination = ref({});
      const onLoadData = (pagination) => {
        if(typeof pagination !=='undefined'){
          formState.page = pagination.current
        }
        Api.wechat_list_material(formState).then((res) => {
          Refpagination.value.current = res.data.material_list.current_page;
          Refpagination.value.pageSize = res.data.material_list.per_page;
          Refpagination.value.total = res.data.material_list.total;
          dataSource.value = res.data.material_list.data;
        });
      };

      const goTo = () => {
        router.push("/client/wechat/material_publish");
      };

      const edit = () => {
        router.push("/client/wechat/material_publish");
      };

      // 初始化获取左侧导航
      onMounted(() => {
        onLoadData();
      });

      return {
        goTo,
        edit,
        dataSource,
        Refpagination,
        onLoadData,
        columns: [{
            title: "id",
            dataIndex: "id",
            key: "id",
            width: "10%",
          },
          {
            title: "类型",
            dataIndex: "type_name",
            key: "type_name",
            width: "10%",
          },
          {
            title: "标题",
            dataIndex: "name",
            key: "name",
            width: "10%",
          },
          {
            title: "创建时间",
            dataIndex: "create_time",
            key: "create_time",
            width: "20%",
          },
          {
            title: "操作",
            dataIndex: "action",
            key: "action",
            width: "10%",
          },
        ],
      };
    },
  });
</script>